import React, { useEffect } from 'react';

import { Container } from 'components/container/Container';
import { isIpad } from 'utils/browserTargeting'

import s from './GreenhouseJobForm.scss';

interface IProps {
  gh_Id: string;
}

export const GreenhouseJobForm = ({ gh_Id }: IProps) => {

  let iframeInterval:any;

  useEffect(() => {

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://boards.greenhouse.io/embed/job_board/js?for="+process.env.GATSBY_GREENHOUSE_BOARD_TOKEN+"&rand="+(Math.random()*100);
    script.onload = () => {
      setTimeout( () => {
        Grnhse.Iframe.load(gh_Id)
        setTimeout( () => {
          window.smoothScroll.disable()
          setTimeout( () => window.smoothScroll.enable(), 10 )
          setTimeout( () => window.smoothScroll.onResize(), 100 )
          setTimeout( ()=> window.smoothScroll.onResize(), 1000)
          setTimeout( ()=> window.smoothScroll.onResize(), 3000)
          if(!isIpad()) {
            const iframe = document.getElementById('grnhse_iframe')
            iframeInterval = setInterval( () => {
              if(iframe!.offsetHeight < 750 ){
                const yDestination = document.querySelector('#formContainer').offsetTop
  
                if(!window.smoothScroll.isMobile){
                  window.smoothScroll.disable()
                  window.smoothScroll.scrollTo(yDestination)
                  window.smoothScroll.onResize()
                  setTimeout( () => window.smoothScroll.enable(), 50)
                } else {
                  window.smoothScroll.onResize()
                  window.scroll(0, yDestination)
                }
  
                clearInterval(iframeInterval);
              }
            }, 2000 )
          }
        }, 500 )
        
      }, 500 )
    };
    document.body.appendChild(script);

    return () => {
      clearInterval(iframeInterval);
    }
  }, []);
  
  return (
    <div className={s.formContainer} id="formContainer">
      <Container>
        <div className={s.formContainer__row}>
          <div className={s.formContainer__row__col}>
            <div id='grnhse_app'></div>
          </div>
        </div>
      </Container>
    </div>
  );
};