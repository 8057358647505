import React from 'react';

import { Seo } from 'components/contentful/seo/Seo';
import { HeroJob } from 'components/hero/HeroJob';
import { GreenhouseJobContent } from 'components/greenhouse/GreenhouseJobContent';
import { GreenhouseJobForm } from 'components/greenhouse/GreenhouseJobForm';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';
import { WrapperBgs } from 'components/container/WrapperBgs'
import { BgLine1 } from 'components/bg-svgs/lines/job/bg-line-1/BgLine1';

interface IProps {
  data?: {
    greenhouseJob: {
      gh_Id: string;
      title: string;
      departments: Array<{ name: string }>;
      content: string;
      location: {
        name: string
      }
    };
  };
  pageContext?: any;
  location?: Location;
}

export const Job = ({ pageContext, location }: IProps) => {
  if (!pageContext) return null;

  const { gh_Id, title, departments, content } = pageContext?.jobData;
  const jobLocation = pageContext.jobData.location.name

  const departmentsList: string = departments.map((department: any, index: number) => {
    if (index + 1 == departments.length) return (department.name)
    return (department.name + ', ')
  });

  return (
    <>
      <Seo
        title={title}
        description={"We're hiring technical and business leaders! Join the world’s most experienced team to bring self-driving technology to market safely, quickly, and broadly. " + `${departmentsList} - ${jobLocation}`}
        url={location!.pathname}
      />

      <BgGradient />
      <WrapperBgs>
        <HeroJob
          title={title}
          location={jobLocation}
          department={departmentsList}
        />
        <BgLine1 />
      </WrapperBgs>

      <GreenhouseJobContent content={content} />

      <GreenhouseJobForm gh_Id={gh_Id} />
    </>
  );
};

export default Job;