import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitText from '../../utils/SplitText/SplitText';


import { Container } from 'components/container/Container';
import { Button } from 'components/button/Button';

import s from './HeroJob.scss';

gsap.registerPlugin(SplitText);

interface IProps {
  title: string;
  location?: string;
  department?: string;
}

export const HeroJob = ({ title, location, department }: IProps) => {

  const titleRef = useRef<HTMLHeadingElement>(null);
  const pushRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  let tl:GSAPTimeline;

  useEffect( ():any => {
    
    if (titleRef.current) {
      tl = gsap.timeline({ paused: true });
      let titleLines = new SplitText(titleRef.current, { type: 'lines'}).lines;
      titleLines = new SplitText(titleLines, { type: 'lines' }).lines;
      const animStartDelay = .5;

      tl
        .addLabel('start')
        .set(pushRef.current, { yPercent: 100 })
        .set(titleLines, { yPercent: 120 })
        .set(titleRef.current, { autoAlpha: 1 })
        .to(titleLines, { duration: 1.5, stagger: 0.1, ease: 'Power3.easeInOut', yPercent: 0 }, `start+=${animStartDelay+.6}`)
        .to(pushRef.current, { duration: 1.5, ease: 'Power3.easeInOut', yPercent: 0 }, `start+=${animStartDelay+.6}`)
        .to(buttonRef.current, { duration: 1.5, ease: 'Power3.easeInOut', alpha: 1 }, `start+=${animStartDelay+1}`)
      tl.play();
    }

    return () => tl && tl.kill()

  }, []);

  return (
    <div className={s.hero}>
      <Container>
        <div className={s.hero__row}>
          <div className={s.hero__row__col}>
            <div className={s.hero__push}><div ref={pushRef} >{location} <span className={s.hero__push__dot}>•</span> {department}</div></div>
            <h1 ref={titleRef} className={s.hero__title} style={{opacity: 0}}>
              {title}
            </h1>
            <div className={s.hero__button} ref={buttonRef} style={{opacity: 0}}>
              <Button label="Go back to careers page" href="/careers#open-positions" hasBackArrow={true} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
