import React, { useEffect, useRef, useState }  from 'react';
import gsap from 'gsap';

import { Container } from 'components/container/Container';

import s from './GreenhouseJobContent.scss';

interface IProps {
  content: string;
}

export const GreenhouseJobContent = ({ content }: IProps) => {

  const [contentValue, setContentValueRender] = useState("");
  const contentRef = useRef<HTMLDivElement>(null);

  const htmlDecode = (html: string) => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      var e = document.createElement('div');
      e.innerHTML = html;
      return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    } else {
      return '';
    }
  };

  useEffect( ():any => {
    gsap.to(contentRef.current, { opacity: 1, duration: .55, delay:2, ease: 'Power3.easeInOut', overwrite: true})

    setTimeout(()=>{
      setContentValueRender(content)
    },100)
    
  }, []);

  return (
    <div className={s.content} >
      <Container>
        <div className={s.content__row} ref={contentRef}>
          <div className={s.content__row__col}>
            <div className={s.content__body} dangerouslySetInnerHTML={{ __html: htmlDecode(contentValue) }} />
          </div>
        </div>
      </Container>
    </div>
  );
};